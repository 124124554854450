import React from "react"

import { Search } from '../components/Search'
import SEO from "../components/seo"

const NotFoundPage = () => {
  const search = Search()
  return (
    <>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <p>But if you were looking for something else ...</p>
      { search }
    </>
  )
}

export default NotFoundPage
